import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
// Box for continuation status change message
const OptOutSICWarning = () => {
  return (
    <Card style={{ marginBottom: 25, marginTop: 10 }}>
      <CardHeader
        subheader={"This Employer ERISA-exempt "}
        style={{ backgroundColor: "#FFCC00" }}
      />
    </Card>
  );
};

class EmployerContinuationStatusDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      continuationStatus:
        this.props.Employer.calculated_continuation_status === 1
          ? "COBRA"
          : "State",
      optOut:
        this.props.Employer.continuation_status === "Opt Out" ? true : false,
    };
  }
  //setContinuationStatusEmployer

  handleSave = () => {
    this.setContinuationStatus(this.props.Employer.portal_id).then((r) => {
      this.props.enqueueSnackbar(this.props.Employer.name + " Updated", {
        variant: "success",
      });
      this.props.RefreshTable();
      this.props.Close();
    });
  };

  setContinuationStatus = (portalID) => {
    var body = JSON.stringify({
      continuation_status: this.state.continuationStatus === "State" ? 0 : 1,
      opt_out: this.state.optOut,
    });
    return new Promise((resolve, reject) => {
      api
        .setContinuationStatusAndOptOutEmployer(portalID, body)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  render() {
    console.log(this.props.Employer);
    const { Open } = this.props;
    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Continuation Status</DialogTitle>

          <DialogContent style={{ height: 300, width: 400 }}>
            <div>
              <Typography variant="body2" color="inherit">
                Please note that the continuation status effects whether or not
                terminations are sent to the COBRA provider.
              </Typography>
              <br />
            </div>
            <FormControl margin="dense" fullWidth>
              <InputLabel htmlFor="states">Continuation Status</InputLabel>
              <Select
                value={this.state.continuationStatus}
                onChange={(e) =>
                  this.setState({ continuationStatus: e.target.value })
                }
                inputProps={{
                  name: "continuationStatus",
                  id: "continuation-status",
                }}
              >
                <MenuItem value={"State"}>State</MenuItem>
                <MenuItem value={"COBRA"}>COBRA Continuation</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.optOut}
                  onChange={(e) => {
                    this.setState({ optOut: e.target.checked });
                  }}
                  value="Confirm"
                  color="primary"
                />
              }
              style={{ marginTop: 20 }}
              label="Opt Out Employer"
            />
            {this.props.Employer.sic_opt_out && <OptOutSICWarning />}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.Close} color="primary">
              Close
            </Button>
            <Button
              name="saveStatusButton"
              onClick={this.handleSave}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(EmployerContinuationStatusDialog)
);
